// extracted by mini-css-extract-plugin
export var atBlog = "index-module--atBlog--bx9Ar";
export var homeWrapper = "index-module--home-wrapper--2f0Ch";
export var mainSlider = "index-module--main-slider--sTENe";
export var mainTitle = "index-module--main-title--pG+J3";
export var meetUs = "index-module--meet-us--yWXGK";
export var metamorphosis = "index-module--metamorphosis---tegu";
export var pricing = "index-module--pricing--k23+U";
export var reason = "index-module--reason--GJMnY";
export var reasonList = "index-module--reasonList---yKgk";
export var reasons = "index-module--reasons--wcIk5";
export var reasonsEmotics = "index-module--reasons-emotics--rerNC";
export var reasonsSubtitle = "index-module--reasons-subtitle--O5NPu";
export var terms = "index-module--terms--OLmLk";
export var videos = "index-module--videos--18sQw";
export var videosTitle = "index-module--videosTitle--bjefo";