import React from 'react';

import { list, wrapper, termText, termTitle } from './term-list.module.scss';

import Title from '../atoms/title';
import SubTitle from '../atoms/subtitle';
import ExpandableCard from '../molecules/expandable-card';
import LinkButton from '../atoms/link-button';

const TermList = () => {
    return (
        <div className={wrapper}>
            <Title>
                Dzięki naszemu przepisowi na metamorfozę,
                <br />
                tysiące osób zmieniły już swój styl życia
            </Title>
            <SubTitle>– zawsze na własnych warunkach!</SubTitle>
            <div className={`${list}`}>
                {terms.map((term, index) => {
                    return (
                        <ExpandableCard
                            key={`term-${index}`}
                            counter={index + 1}
                            contentHeight={200}
                            duration={200}
                            cardStyle={{ backgroundColor: term.bgColor }}
                            counterStyle={{ color: term.titleColor }}
                        >
                            <h4 className={termTitle} style={{ color: term.titleColor }}>
                                {term.title}
                            </h4>
                            <p className={termText} style={{ color: term.textColor }}>
                                {term.text}
                            </p>
                        </ExpandableCard>
                    );
                })}
            </div>
            <Title>Zmień styl życia na własnych warunkach – my Ci w tym pomożemy</Title>
            <LinkButton to="/cennik/" color={'yellow'}>
                Chcę spróbować!
            </LinkButton>
        </div>
    );
};

const terms = [
    {
        title: `Skuteczna metamorfoza to zmiana stylu życia, a zmiana stylu życia to zmiana sposobu myślenia`,
        text: `Będziemy z Tobą pracować nad 3 aspektami metamorfozy jednocześnie: odżywianiem, treningami i nastawieniem. Wieloletnia nauka i praktyka utwierdziły nas w przekonaniu, że zmiana mentalna to więcej niż połowa sukcesu. Nie wiesz, jak się do tego zabrać?  To prostsze, niż Ci się wydaje!`,
        titleColor: '#DD3D66',
        textColor: '#CB98A5',
        bgColor: '#FBEBEE',
    },
    {
        title: `Spotkanie z Tobą traktujemy bardzo poważnie – gwarantujemy indywidualne podejście`,
        text: `Nie mamy gotowców z taką samą dietą lub treningami dla wszystkich – nigdy nic takiego od nas nie dostaniesz. Przeprowadzimy dokładny wywiad, żeby poznać Twoje potrzeby i wyzwania. Jeżeli nie spodoba Ci się nasza propozycja, przygotujemy kolejne – aż poczujesz, że to jest właśnie to.`,
        titleColor: '#4C497C',
        textColor: '#918FAD',
        bgColor: '#F5F0F3',
    },
    {
        title: `Wszystkie zmiany wymagają czasu - zobowiązujemy towarzyszyć Ci w metamorfozie tak długo, jak zechcesz`,
        text: `Wiemy, że pierwsze efekty metamorfozy zobaczysz szybciej, niż myślisz.  Bardzo możliwe, że w międzyczasie zmienią się Twoje cele i priorytety. Jesteśmy przygotowani na wszystkie scenariusze i będziemy stale dostosowywać do nich nasze propozycje.`,
        titleColor: '#3D93DD',
        textColor: '#93BBDD',
        bgColor: '#E7F3FF',
    },
    {
        title: `Diecie-cud mówimy nie. Mamy dla Ciebie lepszy pomysł`,
        text: `Nie wierzymy w: katowanie się niejedzeniem, rygor 5 posiłków dziennie lub przymus jedzenia co 3 godziny. Nigdy nie doradzimy Ci ważenia jedzenia lub liczenia kalorii. To wszystko stereotypy! Z nami możesz nauczyć się jeść tak, żeby się najeść, ale nie przejeść. Mieć z jedzenia przyjemność i chudnąć oraz czuć się coraz zdrowiej i lepiej. I obiecujemy – nie będziesz głodować!`,
        titleColor: '#E37110',
        textColor: '#E3B186',
        bgColor: '#FFF4E5',
    },
    {
        title: `Chcesz zmienić coś w swoim wyglądzie, ale masz niechęć do wszelkich trenerów osobistych?`,
        text: `Rozumiemy Cię – dlatego mamy zupełnie inne podejście. Nie będziemy stać Ci nad głową i zmuszać do męczących ćwiczeń. Nasze treningi będą dostosowane do Twoich możliwości i potrzeb. Chcesz pozbyć się bólu pleców lub kolan? Marzą Ci się smukłe uda, ramiona lub pośladki? A może chcesz tylko zrzucić brzuch? Daj nam znać, a my pomożemy osiągnąć Ci wszystkie cele we Twoim własnym tempie.`,
        titleColor: '#80BE2D',
        textColor: '#B5CC95',
        bgColor: '#E9F2EC',
    },
];

export default TermList;
