import React from 'react';

import {
    button,
    title,
    imgDesktop,
    imgMobile,
    subTitle,
    slide as slideClass,
    slideContent,
    ratioBox,
} from './slide.module.scss';
import useMedia from '../../hooks/use-media';
import { config } from '../../config';

import BigTitle from '../atoms/big-title';
import SubTitle from '../atoms/subtitle';
import LinkButton from '../atoms/link-button';
import RatioImage from '../atoms/ratio-image';

const { mediaRelationsMap } = config;

export default function Slide({ slide }) {
    const [imageUrl, imageAlt] = useMedia({
        media: slide.media,
        relation: mediaRelationsMap.mainImage,
    });
    const [mobileImageUrl, mobileImageAlt] = useMedia({
        media: slide.media,
        relation: mediaRelationsMap.mobileImage,
    });

    return (
        <div className={slideClass}>
            <RatioImage
                className={imgMobile}
                ratioClass={ratioBox}
                alt={mobileImageAlt}
                src={mobileImageUrl}
            />
            <RatioImage
                className={imgDesktop}
                ratioClass={ratioBox}
                src={imageUrl}
                alt={imageAlt}
            />
            <div className={slideContent}>
                <BigTitle className={title} Tag="h4">
                    {slide.title}
                </BigTitle>
                {slide.shortDescription && (
                    <SubTitle light={true} Tag="h5" className={subTitle}>
                        {slide.shortDescription}
                    </SubTitle>
                )}
                {slide.buttonUri && slide.buttonText && (
                    <LinkButton to={slide.buttonUri} className={button}>
                        {slide.buttonText}
                    </LinkButton>
                )}
            </div>
        </div>
    );
}
