import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
    reason,
    reasons,
    atBlog,
    meetUs,
    terms,
    pricing,
    reasonList,
    videos,
    reasonsEmotics,
    reasonsSubtitle,
    mainTitle,
    homeWrapper,
    mainSlider,
    metamorphosis,
    videosTitle,
} from './index.module.scss';
import FaqEmotics from '../assets/images/svg/faq-emotics.svg';
import ReasonsEmotics from '../assets/images/svg/reasons-emotics.svg';

import SEO from '../components/seo';
import Main from '../layouts/main';
import MainSlider from '../components/organisms/main-slider';
import RelatedList from '../components/organisms/related-list';
import PostPreview from '../components/molecules/post-preview';
import MeetUs from '../components/organisms/meet-us';
import TermList from '../components/organisms/term-list';
import MetamorphosisSlider from '../components/organisms/metamorphosis-slider';
import Title from '../components/atoms/title';
import SubTitle from '../components/atoms/subtitle';
import Emotic from '../components/atoms/emotic';
import VideoOpinions from '../components/organisms/video-opinions';
import Pricing from '../components/organisms/pricing';

const IndexPage = () => {
    const data = useStaticQuery(query);

    return (
        <>
            <SEO
                title="Skuteczna dieta i treningi - Metamorfozy ciał - Strefa Przemian"
                titleTemplate="%s"
                description="Nie wierzysz w swoją przemianę? To nic! Będziemy z Tobą pracować nad 3 aspektami metamorfozy jednocześnie: odżywianiem, treningami i nastawieniem."
                structuredData={{
                    '@type': 'Organization',
                    url: data.site.siteMetadata.url,
                    logo: `${data.site.siteMetadata.url}${data.site.siteMetadata.image}`,
                    name: data.site.siteMetadata.title,
                    description: data.site.siteMetadata.description,
                }}
            />
            <Main transparentHeader className={homeWrapper}>
                <section className={mainSlider}>
                    <MainSlider slides={getMainSlides(data)} />
                </section>
                <section className={meetUs}>
                    <Title Tag="h1" className={mainTitle}>
                        Metamorfozy ciał i skuteczne odchudzanie - Strefa Przemian
                    </Title>
                    <MeetUs />
                </section>
                <section className={terms}>
                    <TermList />
                </section>
                <section className={metamorphosis}>
                    <MetamorphosisSlider
                        slides={data.allMetamorphosis.edges.map((edge) => edge.node)}
                    />
                </section>
                <section className={pricing}>
                    <Pricing />
                </section>
                <section className={reasons}>
                    <Emotic emotic={ReasonsEmotics} className={reasonsEmotics} />
                    <Title>Dołącz do tysięcy osób, które nam zaufały</Title>
                    <SubTitle className={reasonsSubtitle}>Zapraszamy Cię, jeśli:</SubTitle>

                    <ul className={reasonList}>
                        <li className={reason}>
                            Chcesz schudnąć dla zdrowia! Pracujemy z insulinoopornością, cukrzycą,
                            chorobą Hashimoto i innymi chorobami autoimmunologicznymi – dosłownie
                            wiemy, z czym się to je!
                        </li>
                        <li className={reason}>
                            Nie wierzysz w swoją przemianę, bo: wiele razy Ci się nie udało, nie
                            wyrabiasz się w rygorze i dyscyplinie albo masz doświadczenie efektu
                            jojo
                        </li>
                        <li className={reason}>
                            Uważasz, że jesteś trudnym i beznadziejnym przypadkiem – zaufaj nam:
                            usłyszeliśmy to od 90% naszych podopiecznych
                        </li>
                        <li className={reason}>
                            Masz dosyć męczących diet, liczenia kalorii, przymusu jedzenia co 3
                            godziny itp.
                        </li>
                    </ul>
                </section>
                <section className={videos}>
                    <div className={videosTitle}>Mamy dobrą wiadomość – jest nas tysiące!</div>
                    <VideoOpinions title="Sprawdź, co mówią nasi klienci:" />
                </section>
                <section className={atBlog}>
                    <RelatedList
                        emotics={FaqEmotics}
                        title="Na blogu"
                        subtitle="Dajemy porady, przepisy, przedstawiamy i rozwiązujemy problemy!"
                        link={{ url: '/blog/', name: 'Wszystkie aktualności' }}
                    >
                        {data.allPost.edges.map((post) => (
                            <PostPreview key={post.node.articleId} post={post.node} />
                        ))}
                    </RelatedList>
                </section>
            </Main>
        </>
    );
};

function getMainSlides(queryData) {
    return queryData.allMainSlide.edges
        .map((edge) => edge.node)
        .filter((slide) => slide && slide?.media.length > 0);
}

const query = graphql`
    {
        allMainSlide(sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    id
                    title
                    shortDescription
                    buttonText
                    buttonUri
                    mobileImageUrl
                    media {
                        ...mediaFields
                    }
                }
            }
        }
        allPost(
            filter: { isPromoted: { eq: true } }
            limit: 3
            sort: { fields: publishedAt, order: DESC }
        ) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
        allMetamorphosis(sort: { fields: createdAt, order: DESC }, limit: 5) {
            edges {
                node {
                    author
                    content
                    imageUrl
                }
            }
        }
        site {
            ...siteFields
        }
    }
`;

export default IndexPage;
