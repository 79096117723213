import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import {
    content,
    card,
    button,
    mist,
    counter as counterClass,
    withCounter,
} from './expandable-card.module.scss';

const ExpandableCard = ({
    children,
    cardStyle,
    counterStyle,
    counter,
    contentHeight,
    duration,
}) => {
    const [expanded, setExpanded] = useState(false);

    const cardButtonRef = useRef(null);

    const handleClick = () => {
        setExpanded(!expanded);
        cardButtonRef.current.blur();
    };

    return (
        <div className={`${card} ${counter ? withCounter : ''}`} style={cardStyle}>
            {counter !== null && (
                <div className={counterClass} style={counterStyle}>
                    {counter}
                </div>
            )}
            <AnimateHeight
                duration={duration}
                height={expanded ? 'auto' : contentHeight}
                className={content}
            >
                {children}
                <div
                    className={mist}
                    style={{
                        backgroundImage: expanded
                            ? 'none'
                            : `linear-gradient(to top, ${cardStyle.backgroundColor}, rgba(255,255,255,0)`,
                    }}
                />
            </AnimateHeight>
            <button onClick={handleClick} ref={cardButtonRef} className={button}>
                {expanded ? 'Zwiń' : 'Rozwiń'}
            </button>
        </div>
    );
};

ExpandableCard.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    cardStyle: PropTypes.object,
    counterStyle: PropTypes.object,
    counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    contentHeight: PropTypes.number,
    duration: PropTypes.number,
};

ExpandableCard.defaultProps = {
    children: null,
    cardStyle: { backgroundColor: '#f5f5f5' },
    counterStyle: {},
    counter: null,
    contentHeight: 0,
    duration: 0,
};

export default ExpandableCard;
