import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
    imgBox,
    link,
    item,
    name,
    list,
    title,
    text,
    wrapper,
    avatar,
    emotics,
    contentBox,
} from './meet-us.module.scss';
import MeetUsEmotics from '../../assets/images/svg/meet-us-emotics.svg';

import Emotic from '../atoms/emotic';
import Title from '../atoms/title';
import Button from '../atoms/button';

const MeetUs = () => {
    //TODO: Gatsby Image - change to Static Image
    const data = useStaticQuery(query);

    return (
        <div className={wrapper}>
            <div className={imgBox}>
                <GatsbyImage
                    image={getImage(data.meetUs)}
                    style={{ maxWidth: '670px', margin: '0 auto' }}
                    alt="Ania Kolasińska i Bartek Szemraj razem"
                />
            </div>
            <div className={contentBox}>
                <Emotic emotic={MeetUsEmotics} className={emotics} />
                <Title className={title}>Poznajmy się!</Title>
                <ul className={list}>
                    <li className={item}>
                        <div className={avatar}>
                            <GatsbyImage
                                image={getImage(data.avatarAnia)}
                                style={{ display: 'block' }}
                                alt="Ania Kolasińska"
                            />
                        </div>
                        <div>
                            <h4 className={name}>Ania Kolasińska</h4>
                            <p className={text}>
                                Nie jestem lekarzem, ani terapeutą. Ale na własnej skórze
                                przekonałam się, czym jest anoreksja i kompulsywne jedzenie. Dzisiaj
                                pomagam wychodzić z tych chorób innym. W e-booku „Jem, aby żyć”
                                znajdziesz wszystkie praktyczne wskazówki, które uratowały mi życie.
                            </p>
                        </div>
                    </li>
                    <li className={item}>
                        <div className={avatar}>
                            <GatsbyImage
                                image={getImage(data.avatarBartek)}
                                style={{ display: 'block' }}
                                alt="Bartek Szemraj"
                            />
                        </div>
                        <div>
                            <h4 className={name}>Bartek Szemraj</h4>
                            <p className={text}>
                                Dzięki postowi przerywanemu (ang. intermittent fasting) setki
                                tysięcy ludzi na całym świecie tracą zbędne kilogramy każdego dnia!
                                Dlaczego tak mało słyszymy o tym w mediach? Sprawdź pierwszy polski
                                e-book o poście przerywanym!
                            </p>
                        </div>
                    </li>
                </ul>
                <Link to="/o-nas/" className={link}>
                    <Button color="yellow">Poznaj nas</Button>
                </Link>
            </div>
        </div>
    );
};

const query = graphql`
    {
        meetUs: file(name: { eq: "meet-us" }) {
            childImageSharp {
                gatsbyImageData(width: 670, quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        avatarAnia: file(name: { eq: "avatar-ania" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 78
                    height: 78
                    quality: 100
                    layout: FIXED
                    placeholder: BLURRED
                )
            }
        }
        avatarBartek: file(name: { eq: "avatar-bartek" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 78
                    height: 78
                    quality: 100
                    layout: FIXED
                    placeholder: BLURRED
                )
            }
        }
    }
`;

export default MeetUs;
