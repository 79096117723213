// extracted by mini-css-extract-plugin
export var avatar = "meet-us-module--avatar--0aTxj";
export var contentBox = "meet-us-module--content-box--ldp4u";
export var emotics = "meet-us-module--emotics--lcjAX";
export var imgBox = "meet-us-module--img-box--zywpu";
export var item = "meet-us-module--item--ZLjQj";
export var link = "meet-us-module--link--jGvF5";
export var list = "meet-us-module--list--n6JJ+";
export var name = "meet-us-module--name--fcnTf";
export var text = "meet-us-module--text--LH1zO";
export var title = "meet-us-module--title--Lq+b2";
export var wrapper = "meet-us-module--wrapper--GMIQz";