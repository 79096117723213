import React from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { slider, sliderNavigation } from './main-slider.module.scss';

import SliderDots from '../atoms/slider-dots';
import ArrowButton from '../atoms/arrow-button';
import Slide from '../molecules/slide';
import useSlider from '../../hooks/use-slider';

const mainSliderConfig = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    pauseOnHover: false,
    pauseOnDotsHover: true,
    speed: 700,
};

const MainSlider = ({ className = '', slides }) => {
    const {
        getPrev,
        getNext,
        sliderSettings,
        slideIndex,
        setSlideIndex,
        isChanging,
        sliderRef,
    } = useSlider(mainSliderConfig);

    return slides.length > 0 ? (
        <div className={`${slider} ${className}`}>
            <SlickSlider {...sliderSettings} ref={sliderRef}>
                {slides.map((slide) => (
                    <Slide slide={slide} key={slide.id} />
                ))}
            </SlickSlider>
            {slides.length > 1 && (
                <div className={sliderNavigation}>
                    <ArrowButton onClick={getPrev} disabled={isChanging} />
                    <SliderDots
                        slides={slides}
                        onClick={setSlideIndex}
                        activeSlideIndex={slideIndex}
                        disabled={isChanging}
                    />
                    <ArrowButton onClick={getNext} rotateDeg={180} disabled={isChanging} />
                </div>
            )}
        </div>
    ) : null;
};

export default MainSlider;
